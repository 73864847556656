export default class SyncCompetencesTemplates
{

    constructor( parent )
    {

        this.parent = parent
        this.uuid = this.parent.uuid
        this.logSign = 'SyncWorker::SyncCompetencesTemplates'
        this.parent.logger.clog( this.logSign, 'initialized' )

        this.processed = []

        this.total = 0
        this.step = 0
        this.lastAction = 0
        this.minInterval = 600000

    }

    showProgress( force )
    {
        if( !this.parent.silent || force )
        {
            this.parent.ui.blockerText( '<strong>Kompetenzen-Vorlagen</strong> werden synchronisiert...' )
            this.parent.ui.updateProgress( this.total, this.step )
        }
    }

    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
        {
            this.parent.logger.clog( this.logSign, 'fetching templates from remote...' )

            let message = {
                method: 'objects.getCompetencesTemplates'
            }

            this.parent.client.request( message )
                .then( response =>
                {

                    return resolve( response.result )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )
    }

    _processQueue( queue )
    {
        return new Promise( resolve =>
        {

            if( 0 < queue.length )
            {

                this.step++
                this.showProgress()

                let item            = queue.shift(),
                    baseClassHelper = this.parent.getBaseClassHelper(),
                    baseClass       = baseClassHelper.get( item.type ),
                    object          = baseClassHelper.getObjectById( item.localId )

                if( !object )
                {
                    baseClass.create( item, undefined, undefined, undefined, undefined, item.localId )
                    return resolve( this._processQueue( queue ) )
                }
                else
                {
                    baseClass.update( item, item.localId, object.remoteId, object.timestamp, object.localKey, undefined, false, true )
                    return resolve( this._processQueue( queue ) )
                }

            }
            else
            {
                return resolve()
            }

        } )
    }

    _processTemplateList( list )
    {

        let templates = {
                categories : {},
                competences: {}
            },
            color     = 'white',
            count     = 0

        for( let l in list )
        {

            let mapSubCompetences = new Map(),
                template          = {
                    templateId    : l,
                    type          : 'competenceCategoryTemplate',
                    localId       : list[ l ].template.localId,
                    title         : list[ l ].template.name,
                    color         : color,
                    labels        : [],
                    description   : list[ l ].template.description,
                    subCompetences: []
                }

            for( let c in list[ l ].categories )
            {

                let subCompetence = {
                    mode : 'remove',
                    value: {
                        type       : 'subCompetenceField',
                        caption    : list[ l ].categories[ c ].title,
                        description: list[ l ].categories[ c ].description,
                        localId    : list[ l ].categories[ c ].localId
                    }
                }

                mapSubCompetences.set( list[ l ].categories[ c ].id, list[ l ].categories[ c ].localId )
                template.subCompetences.push( subCompetence )

            }

            templates.categories[ list[ l ].template.localId ] = template

            /*eslint-disable*/
            for( let c in list[ l ].competences )
            {

                let competence = {

                    templateId        : l,
                    type              : 'competenceTemplate',
                    title             : list[ l ].competences[ c ].title,
                    description       : list[ l ].competences[ c ].description,
                    localId           : list[ l ].competences[ c ].localId,
                    color             : color,
                    classReference    : '',
                    groupReference    : '',
                    yeargroupReference: '',
                    level             : parseInt( c ),


                }

                switch( list[ l ].competences[ c ].id_category )
                {
                    case 0:
                        competence.idCompetenceCategory = template.localId
                        break
                    default:
                        competence.idCompetenceCategory = 'subCompetence:' + mapSubCompetences.get( list[ l ].competences[ c ].id_category )
                        break
                }

                try
                {

                    let subs           = JSON.parse( list[ l ].competences[ c ].subs ),
                        competenceSubs = []

                    for( let s in subs )
                    {
                        competenceSubs.push( {
                            mode : 'remove',
                            value: {
                                '_historic': s,
                                type       : 'partialCompetenceField',
                                caption    : subs[ s ],
                                localId    : this.uuid.generate()
                            }
                        } )
                    }

                    competence.subs = competenceSubs

                }
                catch( e )
                {
                }

                templates.competences[ competence.localId ] = competence

            }

        }

        this.total = count
        this.step = 0
        this.showProgress()

        return templates

    }

    storeTemplates( templates )
    {

        return new Promise( resolve =>
        {

            let queue = []
            for( let id in templates.categories )
            {
                queue.push( templates.categories[ id ] )
            }
            for( let id in templates.competences )
            {
                queue.push( templates.competences[ id ] )
            }
            return resolve( this._processQueue( queue ) )

        } )

    }

    /*eslint-disable*/
    sync()
    {

        return new Promise( resolve =>
        {

            this.parent.logger.clog( this.logSign + ':sync', 'run' )

            let baseClassHelper = this.parent.getBaseClassHelper()
            baseClassHelper.get( 'competenceCategoryTemplate' )
                           .cacheHeatup()
                           .then( () =>
                           {
                               baseClassHelper.get( 'competenceTemplate' )
                                              .cacheHeatup()
                                              .then( () =>
                                              {
                                                  this.step = 0
                                                  this.total = 999

                                                  if( this.parent.f.isOnlineSyncableState()
                                                      && undefined !== this.parent.license
                                                      && undefined !== this.parent.license.license )
                                                  {

                                                      this.showProgress()
                                                      if( this.lastAction < Date.now() - this.minInterval )
                                                      {
                                                          this.prepareSyncables()
                                                              .then( list =>
                                                              {

                                                                  let templates = this._processTemplateList( list )

                                                                  this.storeTemplates( templates )
                                                                      .then( () =>
                                                                      {

                                                                          this.lastAction = Date.now()
                                                                          this.parent.eventManager.dispatch( 'templates-refreshed' )
                                                                          this.parent.logger.clog( this.logSign+':sync', 'finished synchronizing templates' )
                                                                          return resolve()

                                                                      } )

                                                              } )
                                                              .catch( error =>
                                                              {
                                                                  this.parent.logger.cerror( this.logSign + ':sync', error )
                                                                  return resolve()
                                                              } )
                                                      }
                                                      else
                                                      {
                                                          this.parent.logger.clog( this.logSign + ':sync', 'no need for resync: needs to be idle for at least 10 minutes.' )
                                                          return resolve()
                                                      }
                                                  }
                                                  else
                                                  {
                                                      this.parent.logger.clog( this.logSign + ':sync', 'app not in syncable state for templates.' )
                                                      return resolve()
                                                  }

                                              } )

                           } )

        } )

    }

}